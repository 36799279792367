<template>
  <div class="content">
    <div class="content-inner" v-if="data.fullName">
      <div class="title">{{ data.eventName }}</div>
      <el-row>
        <el-col :span="12">
          <div class="user-box">
            <div class="user-info">
              <div class="user-col">
                <div class="item1">姓名</div>
                <div class="item2">{{ data.fullName }}</div>
              </div>
              <div class="user-col">
                <div class="item1">性别</div>
                <div class="item2">{{ data.sex }}</div>
              </div>
              <div class="user-col">
                <div class="item1">参赛号码</div>
                <div class="item2">{{ data.bibNumber }}</div>
              </div>
              <!--              <div class="user-col">-->
              <!--                <div class="item1">国籍</div>-->
              <!--                <div class="item2">{{ data.country }}</div>-->
              <!--              </div>-->
            </div>
            <el-row class="grid-row">
              <el-col :span="12">
                <div class="grid-content grid-content1">
                  <div class="inof-item1">{{ data.rankName }}排名</div>
                  <div class="inof-item2">{{ data.ranking }}</div>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="grid-content grid-content2  last-grid-content">
                  <div class="inof-item1">枪声成绩</div>
                  <div class="inof-item2">{{ data.eventScore | timeFilter }}</div>
                </div>
              </el-col>

            </el-row>
            <el-row class="grid-row">
              <el-col :span="12">
                <div class="grid-content grid-content3">
                  <div class="inof-item1">净成绩</div>
                  <div class="inof-item2">{{ data.netScore | timeFilter }}</div>
                </div>
              </el-col>
              <!--              <el-col :span="8">-->
              <!--                <div class="grid-content grid-content4">-->
              <!--                  <div class="inof-item1">最低配速</div>-->
              <!--                  <div class="inof-item2">4′02″</div>-->
              <!--                </div>-->
              <!--              </el-col>-->
              <el-col :span="12">
                <div class="grid-content grid-content5  last-grid-content">
                  <div class="inof-item1">平均配速</div>
                  <div class="inof-item2">{{ data.avgSpeed ? data.avgSpeed : '--' }}</div>
                </div>
              </el-col>
              <!--              <el-col :span="8">-->
              <!--                <div class="grid-content grid-content6 last-grid-content">-->
              <!--                  <div class="inof-item1">最高配速</div>-->
              <!--                  <div class="inof-item2">4′02″</div>-->
              <!--                </div>-->
              <!--              </el-col>-->
            </el-row>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="score-box">
            <div class="scores">{{ (data.showNet == 1 ? data.netScore : data.eventScore) | timeFilter }}</div>
            <div class="project">{{ data.projectName }}</div>
          </div>
        </el-col>
      </el-row>
      <div class="table-tile">
        <div class="name">
          <span></span>
          <a>分段成绩</a>
        </div>
        <div class="down-btn" style="cursor: pointer" @click="showCert"
             v-if="certImgs.length > 0 && data.status != 6&&data.status != 0">
          点击查看证书
        </div>
      </div>
      <el-table
          stripe
          :header-cell-style="{background:'#34C5F6', color:'white', 'line-height':'48px'}"
          :row-style="{ 'text-align': 'center'}"
          class="table"
          header-align="cneter"
          :data="data.list"
          style="width: 100%"
          v-if="data.sportRule !=2"
      >
        <el-table-column align="center" header-align="center" label="打卡点" prop="currentCheckpoint" width="200">
        </el-table-column>
        <el-table-column align="center" header-align="center" label="打卡时间" prop="checkInTime"></el-table-column>
        <el-table-column align="center" header-align="center" label="用时" prop="elapsedTime"
                         :formatter="elapsedTimeFormat"></el-table-column>
        <el-table-column align="center" header-align="center" label="里程(km)"
                         prop="distanceFromStart"></el-table-column>
      </el-table>

      <el-table
          stripe
          :header-cell-style="{background:'#34C5F6', color:'white', 'line-height':'48px'}"
          :row-style="{ 'text-align': 'center'}"
          class="table"
          header-align="cneter"
          :data="data.circleList"
          style="width: 100%"
          v-else
      >
        <el-table-column align="center" header-align="center" label="圈号" type="index" width="200">
        </el-table-column>
        <el-table-column align="center" header-align="center" label="终点打卡时间" prop="endCheckInTime" width="200">
        </el-table-column>
        <el-table-column align="center" header-align="center" label="分段成绩" prop="elapsedTime"
                         :formatter="elapsedTimeFormat"></el-table-column>
        <el-table-column align="center" header-align="center" label="配速" prop="elapsedTime"
                         :formatter="speedFormat"></el-table-column>
        <el-table-column align="center" header-align="center" label="分段距离(km)"
                         prop="distanceFromPrev"></el-table-column>
      </el-table>
    </div>

    <div v-else class="no-data">没有数据</div>
    <el-dialog
        title="证书预览"
        :visible.sync="dialogVisible">
      <div v-for="src in  certImgs" class="img-box" style="width: 600px;margin: auto;">
        <img :src="src" alt="" class="share-image" style="width: 100%">
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关 闭</el-button>
        <el-button type="primary" @click="saveAsImage">下 载</el-button>
      </span>
    </el-dialog>
    <!--<template v-for="(painting,index) in paintingList">-->
    <!--  <vue-canvas-poster class="poster" :width="painting.width"-->
    <!--                     :height="painting.height" :painting="painting" @success="success"-->
    <!--                     @fail="fail" :key="index"></vue-canvas-poster>-->
    <!--</template>-->
  </div>
</template>

<script>

const date = new Date();
import {certInfo, getIndividualScoresById, certInfoShow} from '@/utils/request.js'
import {timeSecondFilter, timeFilter, timeFilter2} from '@/utils/dateUtil.js'
import api from '../../utils/api';

export default {
  components: {},
  data() {
    return {
      certImgs: [],
      dialogVisible: false,
      showItem: false,
      eventRanksId: null,
      bibNumber: '',
      data: {},
      cretInfoList: [],
      paintingList: [],
      srcs: []
    }
  },
  filters: {
    //处理函数
    timeFilter(value) {
      return timeSecondFilter(value)
    }
  },
  mounted() {
    this.bibNumber = this.$route.query.bibNumber;
    this.eventRanksId = this.$route.query.eventRanksId;
    if (!this.bibNumber || !this.eventRanksId) {
      return
    }
    let that = this;
    getIndividualScoresById({bibNumber: this.bibNumber, eventRanksId: this.eventRanksId}).then(
        (res) => {
          that.data = res.data.data;
          document.title = that.data.eventName;
          that.setCertImg()
          // this.loadCert()
        },
        (err) => {
          console.log(err);
        }
    )
  },
  methods: {
    setCertImg() {
      let that = this;
      that.certImgs = [];
      certInfoShow(that.data.projectId, that.data.bibNumber).then(res => {
        if (res.data.data && res.data.data.length > 0) {
          for (let i = 0; i < res.data.data.length; i++) {
            let item = res.data.data[i]
            that.certImgs.push(`${api.api_root}statistics/front/certInfoImg/${that.data.projectId}/${that.data.bibNumber}/${item}`)
          }
        }
      })
    },
    success(src) {
      console.log('success', src)
      this.srcs.push(src)
    },
    fail(err) {
      console.log('fail', err)
    },
    saveAsImage() {
      let i = 0;
      this.certImgs.forEach(src => {
        /** 新Image对象，可以理解为DOM */
        let image = new Image();
        /** 解决跨域 Canvas 污染问题 */
        image.setAttribute('crossOrigin', 'anonymous');
        image.src = src;
        console.log('src', src);
        /** 生成一个a元素,并创建一个单击事件 */
        let a = document.createElement('a');
        a.download = this.data.bibNumber + '_' + i; // 设置图片名称
        a.href = src; // 将生成的URL设置为a.href属性
        const name = this.data.bibNumber + 'myLink' + (i++);
        a.setAttribute('id', name);
        document.body.appendChild(a);
        this.exportCodeConfirm(name);
      })

    },
    /** 导出/下载 二维码 */
    exportCodeConfirm(name) {
      setTimeout(() => {
        let event = new MouseEvent('click');
        /** 触发a的单击事件 */
        document.getElementById(name).dispatchEvent(event);
      }, 0);
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    loadCert() {
      let that = this;
      certInfo(this.data.projectId, this.data.bibNumber).then(async res => {
        if (res.data && res.data.code == 200) {
          that.cretInfoList = res.data.data;
          if (that.cretInfoList.length > 0) {
            for (let j = 0; j < that.cretInfoList.length; j++) {
              let views = [];
              let cretInfo = that.cretInfoList[j]
              let height = cretInfo.bgHeight;
              let width = cretInfo.bgWidth;

              views.push({
                type: 'image',
                url: cretInfo.bgImg,
                css: {
                  width: width + 'px',
                  height: height + 'px',
                }
              })
              for (let i = 0; i < cretInfo.certItems.length; i++) {

                let item = cretInfo.certItems[i]
                let css = {
                  top: (parseInt(item.y) + parseInt((item.h - item.fontsize) / 2)) + 'px',
                  left: parseInt(item.x) + 'px',
                  fontSize: parseInt(item.fontsize) + 'px',
                  fontWeight: (item.fontweight == 'true' ? 700 :
                      'normal'),
                  color: (item.color ? item.color : '#333'),
                  width: parseInt(item.w) + 'px',
                  // height: parseInt((item.h - item.fontsize) / 2) + 'px',
                  // lineHeight: parseInt(item.h) + 'px',
                  textAlign: item.textAlign,
                  fontFamily: item.fontFamily,
                }
                console.log('top', parseInt(item.y), parseInt(item.y) + parseInt((item.h - item.fontsize) / 2))
                views.push({
                  type: 'text',
                  text: item.value,
                  css: css
                })
              }
              const painting = {
                width: width + 'px',
                height: height + 'px',
                background: '#fff',
                views: views
              }
              console.log('painting', painting)
              that.paintingList.push(painting)
              console.log('Before sleep');
              await that.sleep(1000); // 休眠2秒
              console.log('After sleep');
            }
          }
        }
      })
    },
    showCert() {
      let that = this;
      if (!that.certImgs || that.certImgs.length <= 0) {
        this.$message({
          type: 'error',
          message: '证书未配置',
        });
        return
      }
      that.dialogVisible = true;
    },
    elapsedTimeFormat(row, column) {
      return timeFilter(row.elapsedTime)
    },
    eventScoreFormat(row, column) {
      return timeFilter(row.elapsedTime)
    },
    speedFormat(row, column) {
      if (!row.distanceFromPrev || !row.elapsedTime) {
        return '00′00″';
      }
      const seconds = parseInt(row.elapsedTime / 1000 / row.distanceFromPrev);
      return timeFilter2(seconds)
    },
    handleEdit(index, row) {
      console.log(index, row);
      var that = this;
      that.dialogFormVisible = true;
      that.form.date = row.date;
      that.form.name = row.name;
    },
    addmessage() {
      var that = this;
      that.dialogAddFormVisible = true;
    },
    handleDelete(index, row) {
      console.log(index, row);
      this.$confirm('确定删除吗？', '提示', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'warning',
      })
          .then(() => {
            this.$message({
              type: 'success',
              message: '删除成功',
            });
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            });
          });
    },
  },
};
</script>
<style scoped lang="scss">
.content {
  background: #F8F8F8;
  height: 100vh;

  .content-inner {
    max-width: 1260px;

    .title {
      height: 72px;
      line-height: 72px;
      background: #FFFFFF;
      text-align: left;
      padding-left: 24px;
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }

    .user-info {
      display: flex;
      padding: 30px;
      width: 100%;
      height: 100%;
      background-image: url('../../assets/user_info.png');
      background-size: cover; /* 使图片平铺满整个浏览器（从宽和高的最大需求方面来满足，会使某些部分无法显示在区域中） */
      z-index: -1;
      background-repeat: no-repeat;
      box-sizing: border-box;

      .user-col {
        flex: 1;

        .item1 {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
        }

        .item2 {
          font-size: 24px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          margin-top: 12px;
        }
      }
    }

    .table-tile {
      display: flex;
      width: 100%;
      justify-content: space-between;
      height: 72px;
      background: #FFFFFF;
      line-height: 72px;
      padding: 0 24px;
      box-sizing: border-box;
      margin-top: 20px;

      .name {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;

        span {

          display: inline-block;
          width: 4px;
          height: 16px;
          background: #34C5F6;
          border-radius: 8px;
          vertical-align: middle;
          margin-right: 8px;
        }

        a {
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
        }
      }

      .down-btn {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #34C5F6;
      }
    }

    .table {
      margin-top: 14px;
    }

    .header_row {
      display: flex;
      flex-direction: row;
    }

    .addbutton {
      margin-right: 20px;
    }

    margin: auto;
  }

}


.el-form-item {
  margin-bottom: 0;
}

.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  height: 126px;
  margin-top: 20px;
  margin-right: 20px;
  padding: 30px 0;
  box-sizing: border-box;

  .inof-item1 {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
  }

  .inof-item2 {
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    margin-top: 12px;
  }
}

.grid-content1 {
  background: #3496F6;
}

.grid-content2 {
  background: #34C5F6;
}

.grid-content3 {
  background: #53DC81;
}

.grid-content4 {
  background: #EB4D4D;
}

.grid-content5 {
  background: #F5C427;
}

.grid-content6 {
  background: #B0D41F;
}


.last-grid-content:last-child {
  margin-right: 0;
}

.user-box {
  margin-right: 10px;
  margin-top: 20px;
  height: 126px;
}

.score-box {
  background: #fff;
  height: 418px;
  margin-left: 10px;
  margin-top: 20px;
  background-image: url('../../assets/scores_bg.png');
  background-position-x: center;
  background-position-y: center;
  background-size: 230px 316px; /* 使图片平铺满整个浏览器（从宽和高的最大需求方面来满足，会使某些部分无法显示在区域中） */
  z-index: -1;
  background-repeat: no-repeat;

  .scores {
    font-size: 80px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #34C5F6;
    line-height: 112px;
    padding-top: 138px;
    box-sizing: border-box;
  }

  .project {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 25px;
    margin-top: 6px;
  }
}

.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
  background: red;
}

.no-data {
  padding-top: 300px;
}

.popup-content {
  height: auto;
  background-color: #fff;
  position: relative;
  text-align: left;

  .cert-box {
    position: relative;
  }

  .cret-item {
    position: absolute;
    //left: 0;
    //top: 0;
  }

  .cret-down {
    position: absolute;
    bottom: -120px;
    color: #fff;
    padding: 8px;
    border-radius: 60px;
    width: 120px;
    text-align: center;
    background: #00aaff;
  }
}
</style>
