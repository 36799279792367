<template>
  <div>
    <div v-if="certImg">
      <!--<div v-for="src in srcs" class="img-box" style="width: 600px;margin: auto;">-->
      <img :src="certImg" alt="" class="share-image" style="width: 800px">
      <!--</div>-->
      <div class="dialog-footer" style="margin-top: 100px;margin-bottom: 100px">
        <el-button type="primary" @click="saveAsImage">下 载</el-button>
      </div>
    </div>
    <!--<template v-for="(painting,index) in paintingList">-->
    <!--  <vue-canvas-poster class="poster" :width="painting.width"-->
    <!--                     :height="painting.height" :painting="painting" @success="success"-->
    <!--                     @fail="fail" :key="index"></vue-canvas-poster>-->
    <!--</template>-->

  </div>
</template>

<script>
import {certInfo, roleCertList} from '@/utils/request';
import roleCert from '@/views/role-cert/role-cert.vue';
import {Loading} from 'element-ui';
import api from '../../utils/api';
export default {
  name: 'electron',
  data() {
    return {
      data: {},
      eventId: '',
      certImg: '',
      showItem: false,
      cretInfoList: [],
      paintingList: [],
      srcs: []
    }
  },
  mounted() {
    this.keyword = this.$route.query.keyword
    this.eventId = this.$route.query.eventId
    if (!this.keyword || !this.eventId) {
      return
    }
    this.certImg = `${api.api_root}statistics/front/roleCertImg/${this.eventId}/${this.keyword}`
    // this.loadCert()
  },
  methods: {
    success(src) {
      console.log('success', src)
      this.srcs.push(src)
    },
    fail(err) {
      console.log('fail', err)
    },
    saveAsImage() {
      let i = 0;
      // this.srcs.forEach(src => {
      /** 新Image对象，可以理解为DOM */
      let image = new Image();
      /** 解决跨域 Canvas 污染问题 */
      image.setAttribute('crossOrigin', 'anonymous');
      image.src = this.certImg;
      /** 生成一个a元素,并创建一个单击事件 */
      let a = document.createElement('a');
      a.download = '电子号码布_' + new Date().getTime(); // 设置图片名称
      a.href = this.certImg; // 将生成的URL设置为a.href属性
      const name = 'myLink' + (i++);
      a.setAttribute('id', name);
      document.body.appendChild(a);
      this.exportCodeConfirm(name);
      // })
    },
    /** 导出/下载 二维码 */
    exportCodeConfirm(name) {
      setTimeout(() => {
        let event = new MouseEvent('click');
        /** 触发a的单击事件 */
        document.getElementById(name).dispatchEvent(event);
      }, 0);
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async loadCert() {
      let that = this;
      let loading = Loading.service({
        fullscreen: true,
        lock: true,
        text: '加载中'
      });
      await that.sleep(300);
      roleCertList(that.eventId, that.keyword).then(async res => {
        if (res.data && res.data.code == 200) {
          that.cretInfoList = res.data.data;
          if (that.cretInfoList.length == 0) {
            this.$message({
              type: 'error',
              message: '没有数据',
            });
            loading.close()
            return
          }
          if (that.cretInfoList.length > 0) {
            for (let j = 0; j < that.cretInfoList.length; j++) {
              let views = [];
              let cretInfo = that.cretInfoList[j]
              let height = cretInfo.bgHeight;
              let width = cretInfo.bgWidth;
              views.push({
                type: 'image',
                url: cretInfo.bgImg,
                css: {
                  width: width + 'px',
                  height: height + 'px',
                }
              })

              for (let i = 0; i < cretInfo.certItems.length; i++) {
                let item = cretInfo.certItems[i]
                let css = {
                  top: (parseInt(item.y) + parseInt((item.h - item.fontsize) / 2)) + 'px',
                  left: parseInt(item.x) + 'px',
                  fontSize: parseInt(item.fontsize) + 'px',
                  fontWeight: (item.fontweight == 'true' ? 700 :
                      'normal'),
                  color: (item.color ? item.color : '#333'),
                  width: parseInt(item.w) + 'px',
                  // height: parseInt((item.h - item.fontsize) / 2) + 'px',
                  // lineHeight: parseInt(item.h) + 'px',
                  textAlign: item.textAlign,
                  fontFamily: item.fontFamily,
                }
                views.push({
                  type: 'text',
                  text: item.value,
                  css: css
                })
              }
              const painting = {
                width: width + 'px',
                height: height + 'px',
                background: '#fff',
                views: views
              }
              console.log('painting', painting)
              that.paintingList.push(painting)
              console.log('Before sleep');
              await that.sleep(1000); // 休眠2秒
              console.log('After sleep');
            }
          }
        }
        loading.close()
      })
    },
  }
}
</script>

<style scoped lang="scss">
.popup-content {
  height: auto;
  background-color: #fff;
  position: relative;
  text-align: left;

  .cert-box {
    position: relative;
    width: 800px;
    margin: auto;
  }

  .cret-item {
    position: absolute;
    //left: 0;
    //top: 0;
  }

  .cret-down {
    position: absolute;
    bottom: -120px;
    color: #fff;
    padding: 8px;
    border-radius: 60px;
    width: 120px;
    text-align: center;
    background: #00aaff;
  }
}
</style>
